<template>
  <div class="login">
    <div class="container h-100">
      <div class="mainRow row h-100">
        <div class="col-12 col-md-6 d-flex align-items-center">
          <img
            src="../../assets/logo-transparent-cropped.png"
            alt="Vax Trax Global Logo"
            class="w-100"
          />
        </div>
        <div
          class="
            col-12 col-md-6
            d-flex
            flex-column
            align-items-center
            justify-content-center
          "
        >
          <div class="loginPanel">
            <h2 class="text-center my-3">Registration</h2>
            <span class="text-muted text-center mb-4"
              >Enhancing Community Life</span
            >

            <b-form
              @submit.prevent="onSubmit"
              class="w-100 needs-validation"
              novalidate
            >
              <b-form-group
                id="input-group-1"
                label="Email:"
                label-for="input-1"
              >
                <div class="inputField">
                  <b-icon icon="envelope"></b-icon>
                  <b-form-input
                    id="input-1"
                    type="email"
                    v-model="form.email"
                    aria-describedby="input-1-live-feedback"
                    :state="validateState('email')"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback"
                    >Please check your email</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Mobile No:"
                label-for="input-2"
              >
                <div class="row">
                  <div class="col-3 pr-0">
                    <VueCountryCode class="countryCode" :enabledCountryCode = "true" @onSelect="onSelect">
                    </VueCountryCode>
                  </div>
                  <div class="col-9 pl-0">
                    <div class="inputField">
                      <b-icon icon="telephone"></b-icon>
                      <b-form-input
                        id="input-2"
                        type="tel"
                        v-model="form.mobileNo"
                        aria-describedby="input-2-live-feedback"
                        :state="validateState('mobileNo')"
                        required
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-2-live-feedback"
                        >Please check your mobile
                        number</b-form-invalid-feedback
                      >
                    </div>
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Password:"
                label-for="input-3"
              >
                <div class="inputField">
                  <b-icon icon="lock"></b-icon>
                  <b-form-input
                    type="text"
                    id="input-4"
                    v-model="form.password"
                    aria-describedby="input-4-live-feedback"
                    :state="validateState('password')"
                    v-if="showPassword"
                  >
                  </b-form-input>

                  <b-form-input
                    id="input-3"
                    type="password"
                    v-model="form.password"
                    aria-describedby="input-3-live-feedback"
                    :state="validateState('password')"
                    required
                    v-else
                  ></b-form-input>
                  <div class="showPasswordIconContainer">
                    <b-icon
                      icon="eye-slash-fill"
                      class="showPassword"
                      v-if="showPassword"
                      @click="toggleShowPassword"
                    ></b-icon>
                    <b-icon
                      icon="eye-fill"
                      class="showPassword"
                      v-else
                      @click="toggleShowPassword"
                    ></b-icon>
                  </div>

                  <b-form-invalid-feedback id="input-3-live-feedback"
                    >Password should be minimum 8 characters
                    long</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                class="loginBtn mt-4 mb-3"
              >
                Register
              </b-button>
            </b-form>
            <p class="text-center my-4">
              Already have an account?
              <router-link to="/login">Sign In</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import VueCountryCode from "vue-country-code-select";
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import axios from "axios";
import qs from "qs";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
        mobileNo: "",
        password: "",
        twilioUserId: "",
      },
      selectedCountryCode: null,
      showPassword: false,
    };
  },
  components: {
    VueCountryCode,
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      mobileNo: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(12),
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    resetForm() {
      this.form = [
        {
          email: null,
          mobileNo: null,
          password: null,
        },
      ];

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.selectedCountryCode = dialCode;
    },
    getQueryString(data = {}) {
      return Object.entries(data)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // console.log(this.form);

      let mobileNo = this.selectedCountryCode + this.form.mobileNo;

      console.log(this.form.twilioUserId);
      var twilioCurl = {
        command: `curl "https://api.authy.com/protected/json/users/new" -H  "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi" -d user[email]=${this.form.email} -d user[cellphone]=${this.form.mobileNo} -d user[country_code]=${this.selectedCountryCode}`,
      };
      console.log(twilioCurl);

      // var FormData = require("form-data");÷
      var data = new FormData();
      let command = `curl "https://api.authy.com/protected/json/users/new" -H  "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi" -d user[email]=${this.form.email} -d user[cellphone]=${this.form.mobileNo} -d user[country_code]=${this.selectedCountryCode}`;
      data.append("command", command);

      var config = {
        method: "post",
        url: "https://api.vaxtraxglobal.com/api/v1/twilio",

        data: data,
      };
      axios(config)
        .then(({ data }) => {
          this.form.twilioUserId = data.user.id;
          console.log(this.form.twilioUserId);
          this.$store.dispatch("setTwilioId", data.user.id);

          var data2 = new FormData();
          let command2 = `curl "https://api.authy.com/protected/json/sms/${this.form.twilioUserId}?force=true" -H "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi"`;

          data2.append("command", command2);

          var config2 = {
            method: "post",
            url: "https://api.vaxtraxglobal.com/api/v1/twilio",
            data: data2,
          };

          axios(config2)
            .then(({ data }) => {
              console.log(data);
              axios
                .post(
                  "https://api.vaxtraxglobal.com/api/v1/user",
                  {
                    email: this.form.email,
                    mobileNo: mobileNo,
                    password: this.form.password,
                    twilioUserId: this.form.twilioUserId,
                  },
                  {
                    headers: {
                      role: "user",
                    },
                  }
                )
                .then((data) => {
                  console.log(data);

                  this.$store.dispatch("setData", data.data);
                  this.$router.push(`/verify/signup/${mobileNo}`);
                })
                .catch((resp) => {
                  console.log(resp);
                  alert("some error occurred. Please try again later.");
                });
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
  width: 100%;
  background: #005eaa33;

  @media (max-width: 768px) {
    padding-bottom: 10vh;
  }

  .mainRow {
    min-height: 100vh;

    .loginPanel {
      background: #fff;
      width: 80%;
      height: auto;
      min-height: 450px;
      margin: 0 auto;
      padding: 10px 20px;
      border-radius: 40px;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      flex-direction: column;

      > h2 {
        font-weight: 800 !important;
      }

      > span {
        font-size: 1.4rem;
      }

      a {
        font-weight: bold;
      }

      form {
        label {
          font-size: 14px;
        }
        .inputField {
          position: relative;
          > .b-icon {
            position: absolute;
            left: 15px;
            top: 8px;
            font-size: 20px;
          }
          .showPasswordIconContainer {
            position: absolute;
            right: 15px;
            top: 5px;
            font-size: 20px;
          }
        }
        input {
          border-radius: 20px;
          padding-left: 45px;
        }
        .loginBtn {
          width: 100%;
          border-radius: 20px;
        }
        .countryCode {
          border-radius: 10px;
          background: rgb(241, 241, 241);
          width: 90%;
          z-index: 10;

          &:focus,&:active{
            box-shadow:none !important;
            outline:none !important;
            border:none !important;
          }
        }
      }

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
}
</style>
